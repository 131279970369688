.attestations_container {
    display: flex;
    margin: 0rem;
    color: rgba(0, 0, 0, 0.87);
    font-family: Manrope, Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
}

.attestations_creteria_container {
    background-color: #f0f0f5;
    margin: 1rem;
    display: flex;
    flex-direction: column;
}

.attestations_creteria_header {
    background-color: blue;
    color: #ffffff;
    font-weight: bolder;
    font-size: 1rem;
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    padding: .75rem;
}

.attestations_labels {
    color: #05095e;
    font-weight: bolder;
    font-size: .9rem;
    padding-left: .75rem;   
}

.attestations_labels-wrapper {
    margin-top: .5rem !important;
}
.attestations_labels_highlight {
    color: #05095e;
    font-weight: 800;
    font-size: .9rem;
    /* padding-right: .75rem;   
    padding-left: .75rem;    */
}
.attestations_data_small {
    font-size: .9rem;
    font-weight: 400;
    color:#36505e;
    padding-right: .75rem;    
}
.attestations_data_sp {
    color: #05095e;
    font-weight: bolder;
    font-size: .9rem;
}

.attestations_inputs {
    color: #21130d;
    font-weight: bolder;
    font-size: .9rem;
    margin: .15rem .75rem .35rem .75rem;
    padding: .5rem 0 .5rem .5rem;
    width:75%;
}

.attestation_select {
    color: #21130d !important;
    font-weight: 800 !important;
    font-size: .9rem !important;
    margin-bottom:.93rem !important;
    margin-left: .75rem  !important;
    padding-left: .75rem  !important;
    background-color: #ffffff !important;
    border: .1rem solid #21130d;
}

.attestation_select_list{
    padding-top:1rem !important;
}


.attestations_option_group {
    display: flex;
    flex-direction: row;
}

.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
    padding-top:.5rem !important;
}


.attestations_type_header {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.attestations_type_pending {
    background-color: #f3e7ef;
    padding:.5rem;
    border-radius: .15rem;
    margin-left: 1rem;
    font-weight: bolder;
    font-size: .9rem;
}
.attestations_type_complete {
    background-color: #d7e3f0;
    padding:.5rem;
    border-radius: .15rem;
    margin-left: 1rem;
    font-weight: bolder;
    font-size: .9rem;
}
.attestations_type_cancel {
    background-color: #c8e2ea;
    padding:.5rem;
    border-radius: .15rem;
    margin-left: 1rem;
    font-weight: bolder;
    font-size: .9rem;
}
.attestations_type_noshow {
    background-color: #e7e1ef;
    padding:.5rem;
    border-radius: .15rem;
    margin-left: 1rem;
    font-weight: bolder;
    font-size: .9rem;
}
.attestations_type_preffered {
    background-color: #eaeec4;
    padding:.5rem;
    border-radius: .15rem;
    margin-left: 1rem;
    font-weight: bolder;
    font-size: .9rem;
}
.attestations_type_ppt {
    background-color: #d3e9d5;
    padding:.5rem;
    border-radius: .15rem;
    margin-left: 1rem;
    font-weight: bolder;
    font-size: .9rem;
}

.attestations_container hr {
    margin-left:.1rem;
    margin-right:.1rem;
}

.attestations_container a {
    display: flex;
    font-size:.9rem;
    align-items: center;
}


.attestations_container_button {
    display: flex;
    justify-content: flex-end;
    padding-right: .5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
}

.attestations_list {
    width: 100%;
    border-collapse: collapse;
}


.attestations_list thead td{
    border: .01rem solid #d2d5ec;
    background-color: #f0f0f5;
    padding:.5rem;
}
.attestations_list tr td{
    border: .01rem solid #95b6c1;
    padding-left: 1rem;
    /* vertical-align: baseline; */
}   

.attestations_list button {
    width:9rem;
}

.attestations_list_highlight_table {
    width:100%;
    border-collapse: collapse;
    /* margin-top: 1rem; */

}

.attestations_list_highlight_table h3 {
    text-decoration: underline;
}




.attestations_list_highlight_table  tr {
    width:50%;
    border-collapse: collapse;
    border-style : hidden!important;   
}

.attestations_list_highlight_table  hr {
    margin-left: .1rem;
    margin-right: .1rem;
    border-top: .1rem solid #95b6c1;
}

.white_row {
    background-color: #ffffff;
    padding: .05rem;
}

.underline {
    text-decoration: underline;
}

.center {
    justify-content: center;
    display: flex;
}

.vSpace {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.small_font {
    font-size: .75rem;
}

.time_input {
    background-color: transparent;
    border:0;
    outline: .1rem solid #d2d5ec;
    width:5rem;
    justify-content: center;
    text-align: center;
}