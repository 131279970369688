 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .grid-caption {
  margin-top: 10px;
  font-size: 12px;
  width: 100%;
  font-weight: bold;
  padding-left: 5px;
  border-bottom: 1px solid #ffffff;
  font-family: 'Manrope';
  color: #707070;
} */
.menu-nav-item{
  display: flex;
}

/* .grid-inout-wrapper {
  text-align: left;
  float: left;
  width: 90%;
  padding: 2px 0px;

} */

.MuiInput-underline:before {
  border: none !important;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
  position: absolute;
  right: 0;
  top: calc(50% - 11px) !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiInput-root {
  padding-bottom: 7px;
}
.display-radio-group{
  display: block !important;
}
.menu-group-header {
  background: #f5f5f5;
  color: #261f1fbf;
  font-weight: bold;
  padding: 9px;
  font-size: 11px;
  text-align: left;
  text-transform: uppercase;
}
 
.input-radio-group {
  display: flow-root !important;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  /* font-family: 'Muli' !important; */
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  background-color: #fff;
}

.css-10hburv-MuiTypography-root {
  margin: 0;
  /* font-family: 'Muli' !important; */
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}
.css-9wziol-MuiTypography-root {
  margin: 0;
  /* font-family: 'Muli' !important; */
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 32px;
}

.menu-group-icon {
  margin-top: 0px;
  height: 26px;
  margin-bottom: -6px;
}
.faq-content-style{
  text-align: left;
}

.trip-results-data-table tbody tr:hover {cursor: pointer;background-color: #f6f9fc;box-shadow: 0 0 7px 4px #ebebf173;}

.trip-results-head-cell {
    background: #f5fafe  !important;
    color: #033d5f  !important;
    font-size: 14px  !important;
    font-weight: bold  !important;
    font-family: 'Muli'  !important;
    border: 1px solid #dee2e6  !important;
}
.trip-results-head-cell-fone {
  background: #f5fafe  !important;
  color: #033d5f  !important;
  font-size: 14px  !important;
  font-weight: bold  !important;
  font-family: 'Muli'  !important;
  border: 1px solid #dee2e6  !important;
}
.trip-results-data-cell {
    border: 1px solid #dee2e6;
    color: #36505e  !important;
    font-family: 'Muli'  !important;
    font-size: 12px  !important;
}

.trip-results-data-cell-head {
  text-align: left !important;
  /* border: 1px solid #dee2e6; */
  border: 1px solid #dee2e6   !important;
  color: #36505e  !important;
  font-family: 'Muli'  !important;
  font-size: 12px  !important;
  padding: 3px  10px !important;

}

.trip-results-data-cell-fone {
    text-align: left !important;
    /* border: 1px solid #dee2e6; */
    border-bottom: 1px solid #dee2e6   !important;
    color: #36505e  !important;
    font-family: 'Muli'  !important;
    font-size: 12px  !important;
    padding: 3px  3px  3px  3px !important;

}

.trip-results-data-cell-highlight {
  text-align: left !important;
  font-family: 'Muli'  !important;
  font-size: 12px  !important;
  font-weight: bold;
  padding: 3px  3px  3px  3px !important;
}

.trip-results-data-cell-input {
  text-align: right !important;
  font-family: 'Muli'  !important;
  font-size: 12px  !important;
  font-weight: bold;
  border-bottom: 1px solid #dee2e6   !important;
  padding: 3px  3px  3px  3px !important;
}

.schedule-comment-row td {
    padding: 0px 8px;
}

.emptyRow td {
    padding: 0px !important;
    height: 1px;
}
.schedule-inv-row {
  background: #efefef;
  border-top: 1px solid #e0e0e0;;
  border-left: 1px solid #e0e0e0;;
  border-right: 1px solid #e0e0e0;;
}
.schedule-comment-row {
  border-left: 1px solid #e0e0e0;;
  border-right: 1px solid #e0e0e0;;
}

.table-hover tbody tr:hover {}

table.MuiTable-root.trip-results-leg-table.css-rqglhn-MuiTable-root {}
.invoice-dialog{
width: 100% !important;
max-width: 100% !important;

}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  
  max-width: 90% !important;
  
}

.trip-results-leg-table {
  border: 1px solid;
}

.trip-results-leg-table thead th {
  background: #e7f0fa;
  font-weight: bold;
  border-left: 1px solid;
}

.trip-results-leg-table tbody td {
  border-left: 1px solid #e0e0e0;
}

.trip-popup-bold{
  text-align: center;
  font-weight: bold;
}


.trip-popup-normal{
  text-align: center;
  
}
.trip-popup-allcaps{
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  
}

.sortable-title {
  float: left;
  width: 75%;
  height: 45px;
  word-wrap: break-word;
  max-width: 200px;
}

.sort-wrapper {
  float: left;
  width: 100%;
  /* position: absolute; */
  display: flex;
  /* margin-top: -15px; */
  /* margin-right: -18px; */
  float: left;
}

.sort-wrapper-up {
  float: left;
  width: 22px;
  height: 20px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 4px;
  margin-right: 3px;
}

.sort-wrapper-down {
  float: left;
  width: 22px;
  height: 20px;
  cursor: pointer;
  vertical-align: top;
  padding-top: 1px;
  border: 1px solid;
  border-radius: 4px;
}
 

.front-login-section {
  background: #f5f7fa;
  padding: 21px  !important;
}

.front-banner-section {
  background: #1d64a1;
}
.signin-banner-text {
  color: white;
  font-size: 43px;
}

.header-user-name {
  font-size: 16px;
  
  width: 50%;
  min-width: 147px;
  color: #1976d2;
  padding: 4px;
  border-radius: 8px;
}

.under-constructions-menu {
  color: #ffbebe !important;
}

.recharts-tooltip-wrapper {
  background: white;
  /* border: 1px solid gray; */
  padding: 0px 10px;
  border-radius: 5px;
  height: 49px;
  line-height: 1;
  box-shadow: 0px 1px 0px 1px #c4c4c4;
}
.driver-row-expired{
  background-color: #f8abab;
  color: rgb(172, 0, 0) !important;
}
.driver-row-expired td{
  
  color: rgb(117, 0, 0) !important;
}
.driver-row-expired:hover{
  
  background-color: rgb(255, 213, 213) !important;
  color: rgb(117, 0, 0) !important;
}
.driver-row-week{
  background-color: #ff8a8a;
}
.driver-row-week:hover{
  background-color: #ff8a8a;
}
.driver-row-twoweek{
  background-color: #ffa700;
}
.driver-row-month{
  background-color: #fff400;
}
.driver-row-three-months{
  background-color: #c7e98e;
}
.driver-row-year{
  background-color: #88e96b;
}
.driver-row-more-year{
  background-color: white;
}
.driver-data-cell{
  font-size: 12px;
  padding:6px !important;

}
 

.MuiBox-root {
  height: auto !important;
}


div#panel1a-header {
  background: #1976d2;
  color: white;
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 23px !important;
}
div#panel2a-header {
  background: #ee8524;
  color: white;
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 23px !important;
}

input {
  /* border: none !important; */
}

input[type="text"] {
  border: 1px solid #bedfff !important;
  height: 12px !important;
  padding: 7px !important;
  /* border-radius: 3px; */
  font-size : 14px;
  margin-left : 5px;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  height: 28px;
  background: #bedfff;
  margin-top: 7px;
  /* color: white; */
  border-radius: 1px;
  left: 7px;
  top: 2px;
}

/* .search-input-box-dob {

  margin-left: 5px !important;
  padding: 0px 4px !important;
  width: 150px;
} */
.date-clear-btn {
  padding: 1px 9px !important;
  margin: 2px 7px !important;
}





fieldset{
  border: none !important;
}

button.MuiButton-root {
  margin: 3px !important;
}

.trip-status-check-wrap {
  float: left;
  margin: 3px 11px;
  font-size : 14px;
}

.signin-input input {
  font-size: 18px;
  padding: 10px !important;
  border: 1px solid #b5c4d1 !important;
  height: 23px !important;
  color: gray !important;
}

.css-h3fle9-MuiTable-root {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 250px !important;
  font-size: 12px;
}

.sortable-title-mobile {
  padding: 1px 8px;
}

.trip-results-data-cell-fone {
  border-bottom: 1px solid #dee2e6   !important;
  padding: 1px 6px;
}
.grid-result-wrapper-cell-phone {
  border-bottom: 4px solid  #dee2e6 !important;
  padding: 0px 0px !important;
}
.grid-header-mobile-wrapper {
  padding: 0px 0px !important;
  background: #f5fafe;
  border-bottom: 4px solid #dee2e6;
}
.sign-in-form {
  padding: 7px 10px;
}
@media only screen and (max-width: 800px) {

  .MuiContainer-root{
    margin: 0% !important;
    padding: 2px !important;

  }
  .MuiGrid-root>.MuiGrid-item {
    padding: 1% !important;
    font-size: 16px !important;
}
  
.MuiPaper-root{
  padding: 0% !important;
}
.front-login-section {
  margin-left: 20px  !important;
  margin-right: 1px  !important;
  padding: 14px !important;
  margin-top: 21px  !important;
}

.sign-in-form {
  padding: 7px 52px;
}
}

.sign-in-title {
  font-size: 14px !important;
  font-weight: bold !important;
  margin-top: 16px !important;
  /* border-bottom: 3px solid #33acc659; */
  text-transform: uppercase !important;
  letter-spacing: 6px !important;
  color: #5ea3c9 !important;
}

.sign-in-form {
  padding: 7px 52px;
  background: white;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 34px;
  border-radius: 4px;
  margin-top: 17px;
}

.MuiInputLabel-outlined {
  background: white;
  padding: 4px;
  margin-top: -6px;
  border-radius: 8px;
}

.signin-input input {
  BACKGROUND: white !important;
  border-radius: 4px;
}
.mobile-button-a {
  /* border-bottom: 1px solid; */
  padding: 0px 2px;
  margin: 3px 2px;
  /* text-decoration: underline; */
  color: #1a76d2;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #f5fafe;
}


input#userName {
  margin-left: 0px !important;
}
.search-string-class {
  text-align: left;
  background: aliceblue;
  width: 100% !important;
  padding: 4px;
  color: #25065c;
  font-size: 14px;
}
.MuiAutocomplete-inputRoot{
  padding: 0px !important;
}
.Mui-error .MuiOutlinedInput-notchedOutline {
  border-bottom: 3px solid  #db0f0f !important;
  padding: 0px !important;
}
.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputAdornedEnd {
  padding: 2px;
}


.MuiListItemButton-gutters.Mui-selected {
  border-radius: 0 25px 25px 0 !important;
  background: white !important;
  color: #1976d2;
  font-weight: bold;
}

nav.MuiList-root {
  background: whitesmoke;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  margin: -1px !important;
}

.menu-item-class-name span {
  font-size: 12px !important;
}


.menu-item-class-name {
  padding-left: 15px;
}

.dashboard-notification {
  text-align: right;
}

.main-dashbord-widget-title {
  padding: 12px;
}

.main-dashboard-card-header-custom span {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #1F1F1F;
  margin-bottom: 2px;
  padding: 0px;
}

@media (max-width: 600px) {
  .header-user-name-welcome {
    float: left;
    font-size: 16px;
    color: rgb(167, 167, 167);
    font-family: "Manrope", sans-serif;
    line-height: 28px;
  }

  .header-user-name-value {
    float: left;
    font-size: 16px;
    color: rgb(22, 22, 22);
    font-family: "Manrope", sans-serif;
    line-height: 12px;
    font-weight: 700;
  }

  .main-dashbord-container {
    margin-top: 33px;
    padding-right: 30px;
  }

  .trip-results-data-table-container {
    width: 340px;
  }

  .main-dashboard-card-header-sm {
    padding: 16px;
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .header-user-name-welcome {
    float: left;
    font-size: 25px;
    color: rgb(167, 167, 167);
    font-family: "Manrope", sans-serif;
    line-height: 28px;
  }

  .header-user-name-value {
    float: left;
    font-size: 25px;
    color: rgb(22, 22, 22);
    font-family: "Manrope", sans-serif;
    line-height: 28px;
    font-weight: 700;
  }

  .main-dashbord-container {
    /* margin-left: 27px; */
    margin-top: 33px;
    padding-right: 30px;
  }

  .main-dashboard-card-header-sm {
    padding: 16px;
  }
}

@media (min-width: 901px) {
  .header-user-name-welcome {
    float: left;
    font-size: 30px;
    color: rgb(167, 167, 167);
    font-family: "Manrope", sans-serif;
    line-height: 28px;
  }

  .header-user-name-value {
    float: left;
    font-size: 30px;
    color: rgb(22, 22, 22);
    font-family: "Manrope", sans-serif;
    line-height: 28px;
    font-weight: 700;
  }

  .main-dashbord-container {
    /* margin-left: 27px; */
    margin-top: 33px;
    padding-right: 30px;
  }
}

.arrow {
  border: solid #939393;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  width: 2px !important;
  height: 2px !important;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.screen-sub-title {
  color: #a7a7a7;
}
.trip-results-data-table {
  min-width: 0px !important;
  width: 339px;
}.trip-row-legend-cap {
  padding: 0px !important;
}

.trip-row-legend {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 10px;
}

.trip-row-legend-val {
  margin: 0 !important;
  padding: 0 !important;
  text-align: right !important;;
}

.trip-row-legend td {
  font-size: 12px  !important;
  color: rgb(77, 77, 77) !important;
}

.main-dashboard-date-range-wrapper {
  position: absolute !important;
  z-index: 99994 !important;
  min-width: 680px;
}

.date-range-card {
  overflow: visible !important;
}

.date-range-picker-button {
  padding: 0px 13px !important;
  color: #1f3167 !important;
  border-color: #1f3167 !important;
}

.schedule-submit-pending{
  background: #1976d2 !important;
  color: white !important;
  padding: 5px !important;
    font-size: 10px !important;

}
.schedule-no-record{
  background: rgba(224, 224, 224, 1) !important;
  color: #033d5f   !important;
  padding: 5px !important;
    font-size: 10px !important; 
}
.schedule-saved {
    background: #19d28b !important;
    color: white !important;
    padding: 5px !important;
    font-size: 10px !important;
}

.schedule-leg-row td {
    
    border: none;
   /* border: 1px solid #ededed; */
    padding: 0px;
    text-align: center;
}

.schedule-leg-row {
    margin-top: 15px;
}

.schedule-leg-row {
    /* border-left: 4px solid #a0f99b; */
}
.schedule-update-cell-error{
	 border: 1px solid red !important; 
}
.schedule-update-cell-error input{
	border: none !important; 
}
.hover-row:hover {
  cursor: pointer;
}

.material-table-custom .css-1mbunh-MuiPaper-root{
  padding-left: 3px !important;
  padding-right: 3px !important;
}