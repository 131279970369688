.group-wrapper {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    padding: 2px;
    margin: 15px 2px;
    box-shadow: 1px 1px 1px gray;
}

.group-trip-container {
    border: 1px solid #bedfff;
    border-collapse: collapse;
    margin: 2px  0px;
    padding: 2px;
    border-radius: 2px;
    font-size: 12px;
    height: 31px;
    font-family: Muli, Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #033d5f!important;;
}





.schedule-styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 12px;
    /* font-family: sans-serif; */
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}



.schedule-styled-table-thead-tr {
    background-color:#f5fafe;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    border-bottom:  thistle solid #dee2e6;
}

.schedule-styled-table tr {
    border-bottom: 1px solid #bedfff;
}


.schedule-styled-table th,
.schedule-styled-table td {
    padding: 12px 15px;
    color: #000000;
}

.schedule-styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.schedule-styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.schedule-styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #f5fafe;
}

.schedule-styled-table tbody tr.active-row {
    font-weight: bold;
    color: #f3f3f3;
}

.schedule-icons {
    font-size: 1.1rem !important;
    color:#033d5f;
    padding:0;
}

.Schedule-edit-input {
    font-size: 1.1rem !important;
}

.input-time{
    background:transparent;
    background-color: transparent;
    margin-left: .5rem;
    margin-top: 0;
    font-weight: 600;
    font-size: .9rem;
    border: none;
}


.flex__child {
    flex: 1 0 auto;
    max-width: 100%;
    }