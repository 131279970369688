.grid-header{
    width:10%;
}
.grid-inout-wrapper {
    text-align: left;
    float: left;
    width: 90%;
    padding: 2px 0px;
    justify-content: flex-start;
    /* border-bottom: 1px solid #d9d9d9; */
  }

  
.grid-caption {
    margin-top: 10px;
    font-size: 12px;
    width: 100%;
    font-weight: bold;
    padding-left: 5px;
    border-bottom: 1px solid #ffffff;
    font-family: 'Manrope';
    color: #343a40;
  }

  .grid-caption-wrapper {
    background: #f9f9f9;
    border: none;
    padding-right: 4px;
    display: flex;
    justify-content: flex-start;
  }

  .radio-wrapper {
    text-align: left;
    padding-left: 3px !important;
    color: #343a40;
  }

  .cust-radio-froup span {
    font-size: 11px !important;
    margin-left: 10px  !important;
    padding: 0px  !important;
  }
  

  .datepicker-wrapper {
    text-align: left;
    width:fit-content;
    /* border: 1px solid #3498db; */
  }

  .search-input-box {
    margin-left: 5px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    width: 250px;
  }

  .search-input-box-dob {

  margin-left: 5px !important;
  padding: 0px 4px !important;
  width: 150px !important;
}

  .search-input-select {
    padding: 0px !important;
    width: "100%";
    border: 1px solid #3498db  !important;
    border-radius: 5px  !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-left: 10px !important;
  }

  .search-input-select-menuitem{
    padding: 0px !important;
    color: red  !important;
  }

  .trip-status-check-wrap {
    float: left;
    margin: 3px 11px;
    font-size : 14px;
    color: #343a40;
    margin-left: 0px;
  }
  

  .thin-border-select {
    border-width: 1px;
    border-style: solid;
    border-color: #3498db; /* Specify your desired border color */
    border-radius: 4px;
    padding: 1px; /* Optional: adjust the padding as per your requirement */
  }

  .thin-border-select select {
    padding-left: 8px;
    font-size: 14px;
  }
